<template>
  <div>
    <div class="row">
      <div :class="[scenarioViewGroup==='L' ? 'col-12' : 'col-xs-12 col-sm-6 col-md-4 col-lg-4', 'scenario-list']"> 
        <!-- 시나리오 목록 -->
        <c-table
          ref="table"
          title="LBL0001165"
          :columns="gridColumns"
          :gridHeight="setheight"
          :data="sop.scenarios"
          :merge="scenarioViewGroup==='L' ? grid.merge : []"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&!disabled"
          :customTrClass="setTrClass"
          selection="multiple"
          rowKey="ramJsaAssessScenarioId"
          @callbackProxy="callbackProxy"
          @innerBtnClicked="innerBtnClicked"
          @table-data-change="tableDataChange"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- 이전평가결과조회 -->
              <c-btn 
                v-show="isCompared" 
                label="LBL0001166" 
                icon="compare_arrows" 
                @btnClicked="compareScenario" />
              <!-- 삭제 -->
              <c-btn v-if="editable&&!disabled" label="LBLREMOVE" icon="remove" @btnClicked="removeScenario" />
              <c-btn 
                v-if="editable&&!disabled" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="sop.scenarios"
                mappingType="PUT"
                label="LBLSAVE" 
                icon="save"
                @beforeAction="saveScenario"
                @btnCallback="saveScenarioCallback" />
            </q-btn-group>
          </template>
          <template v-slot:suffixTitle>
            <q-btn-group outline class="scenario-view-group">
              <q-btn 
                :outline="scenarioViewGroup!=='L'"
                :color="scenarioViewGroup==='L' ? 'indigo' : 'grey'" 
                size="11px" label="" icon="view_list"
                @click="listView"
              >
                <q-tooltip>
                  <!-- 목록형 -->
                  {{setLabel('LBL0001167')}}
                </q-tooltip>
              </q-btn>
              <q-btn 
                :outline="scenarioViewGroup!=='C'"
                :color="scenarioViewGroup==='C' ? 'indigo' : 'grey'" 
                size="11px" label="" icon="space_dashboard"
                @click="cardView"
              >
                <q-tooltip>
                  <!-- 카드형 -->
                  {{setLabel('LBL0001168')}}
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='customCol'">
              <component
                :is="imprComponent"
                :col="col"
                :props="props"
                :inputEditable="editable && Boolean(props.row.ramJsaAssessScenarioId)"
                :requestContentsCols="requestContentsCols"
                tableKey="ramJsaAssessScenarioId"
                ibmTaskTypeCd="ITT0000015"
                ibmTaskUnderTypeCd="ITTU000020"
                @imprChange="imprChange"
              />
            </template>
            <template v-else-if="col.name==='item'">
              <q-item class="card-scenario-list">
                <q-item-section>
                  <q-item-label class="scenario-card-title">{{`${props.row['jobName']} - ${props.row['riskHazardNameClass']}`}}</q-item-label>
                </q-item-section>
                <q-item-section side style="padding-right: 0px;">
                  <q-btn 
                    unelevated  dense
                    color="primary"
                    label="▶"
                    @click="getDetail(props.row.ramJsaAssessScenarioId)" />
                </q-item-section>
              </q-item>
            </template>
            <template v-else-if="col.name==='riskHazardPicture'">
              <q-btn 
                unelevated round dense
                size="7px"
                color="primary" 
                icon="wallpaper"
                @click.stop="openHazardPicture(props)" />
            </template>
            <template v-else-if="col.name==='picture'">
              <q-btn 
                unelevated round dense
                size="7px"
                color="primary" 
                icon="wallpaper"
                @click.stop="openImprPicture(props)" />
            </template>
          </template>
        </c-table>
      </div>
      <div v-if="scenarioViewGroup==='C'" class="col-xs-12 col-sm-6 col-md-8 col-lg-8 scenario-card">
        <q-form ref="editForm">
          <div class="row">
            <div class="col-5">
              <!-- 상세정보 -->
              <c-card title="LBL0001169" class="cardClassDetailForm no-margin">
                <template slot="card-button">
                  <q-btn-group outline >
                    <!-- 저장 -->
                    <c-btn 
                      v-show="editable && !disabled" 
                      :isSubmit="isSaveUnit"
                      :url="saveUnitUrl"
                      :param="scenario"
                      mappingType="PUT"
                      label="LBLSAVE" 
                      icon="save"
                      @beforeAction="saveScenarioUnit"
                      @btnCallback="saveScenarioUnitCallback" />
                  </q-btn-group>
                </template>
                <template slot="card-detail">
                  <div class="col-12">
                    <!-- 사진 -->
                    <c-upload-picture
                      height="300px"
                      :attachInfo="attachHazardInfo"
                      :editable="editable&&!disabled&&selectScenario">
                    </c-upload-picture>
                  </div>
                  <div class="col-12">
                    <!-- 작업 - (평가구분)유해위험요인 -->
                    <c-text
                      :disabled="true"
                      :editable="editable"
                      label="LBL0001170"
                      name="scenarioTarget"
                      v-model="scenarioTarget">
                    </c-text>
                  </div>
                  <div class="col-6">
                    <!-- 관련법규 -->
                    <c-text
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="LBL0001021"
                      name="relationLaw"
                      v-model="scenario.relationLaw">
                    </c-text>
                  </div>
                  <div class="col-6">
                    <!-- 위험등록부 -->
                    <c-checkbox
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :isFlag="true"
                      label="LBL0001171"
                      name="riskbookFlag"
                      v-model="scenario.riskbookFlag"
                    />
                  </div>
                  <div class="col-12">
                    <!-- 원인 -->
                    <c-text
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="LBL0001006"
                      name="riskOccurrenceCause"
                      v-model="scenario.riskOccurrenceCause">
                    </c-text>
                  </div>
                  <div class="col-12">
                    <!-- 결과 -->
                    <c-text
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="LBL0001007"
                      name="riskOccurrenceResult"
                      v-model="scenario.riskOccurrenceResult">
                    </c-text>
                  </div>
                  <div class="col-6">
                    <!-- 평가일 -->
                    <c-datepicker
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="LBL0001013"
                      name="assessDate"
                      v-model="scenario.assessDate"
                    />
                  </div>
                  <div class="col-6">
                    <!-- 평가자 -->
                    <c-field
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :data="scenario"
                      label="LBL0001014"
                      name="assessUserId"
                      v-model="scenario.assessUserId"
                      @username="(val) => { scenario.assessUserName = val }" />
                  </div>
                </template>
              </c-card>
            </div>
            <div class="col-7">
              <!-- 개선정보 -->
              <c-card title="LBL0001172" class="cardClassDetailForm no-margin">
                <template slot="card-detail">
                  <div class="col-4">
                    <!-- 사진 -->
                    <c-upload-picture
                      :attachInfo="attachBeforeInfo"
                      :editable="editable&&!disabled&&selectScenario">
                    </c-upload-picture>
                  </div>
                  <div class="col-5">
                    <!-- 현재안전조치 -->
                    <c-textarea
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :rows="3"
                      label="LBL0001025"
                      name="currentSafetyMeasures"
                      v-model="scenario.currentSafetyMeasures">
                    </c-textarea>
                  </div>
                  <div class="col-3">
                    <!-- 개선 전 위험도 -->
                    <q-list dense bordered separator>
                      <q-item>
                        <div class="card-risk-info detail-risk">
                          <q-item-section>
                            <!-- 빈도 -->
                            <q-item-label>{{setLabel('LBL0001009')}}</q-item-label>
                          </q-item-section>

                          <q-item-section side>
                            <q-item-label>{{scenario.beforeFrequency}}</q-item-label>
                          </q-item-section>
                        </div>
                        <q-separator spaced vertical />
                        <div class="card-risk-info detail-risk">
                          <q-item-section>
                            <!-- 강도 -->
                            <q-item-label>{{setLabel('LBL0001010')}}</q-item-label>
                          </q-item-section>

                          <q-item-section side>
                            <q-item-label>{{scenario.beforeStrength}}</q-item-label>
                          </q-item-section>
                        </div>
                      </q-item>
                      <!-- <q-separator spaced inset /> -->
                      <q-item class="detail-risk">
                        <q-item-section>
                          <!-- 위험도 -->
                          <q-item-label>{{setLabel('LBL0001011')}}</q-item-label>
                        </q-item-section>

                        <q-item-section side class="detail-risk-num">
                          <q-item-label>{{scenario.beforeRisk}}</q-item-label>
                        </q-item-section>
                        <q-item-section v-if="!disabled&&selectScenario" side class="detail-risk-num">
                          <q-btn 
                            unelevated round dense
                            size="10px"
                            color="primary" 
                            icon="create">
                            <q-popup-proxy ref="beforeRiskProxy">
                              <component
                                :is="riskPopComponent"
                                width="800px"
                                :props="{
                                  row: {
                                    ramMatrixId: scenario.ramMatrixId
                                  }
                                }"
                                :editable="editable&&!disabled&&selectScenario"
                                @callback="(data, color) => callbackCardProxy(data, color, 'beforeRiskProxy')"
                              />
                            </q-popup-proxy>
                          </q-btn>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </div>
                  <div class="col-12">
                    <!-- 개선진행여부 -->
                    <c-radio
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :comboItems="improveFlagItems"
                      label="LBL0001173"
                      name="improveFlag"
                      v-model="scenario.improveFlag"
                      @datachange="improveFlagChange">
                    </c-radio>
                  </div>
                  <div class="col-12">
                    <!-- 개선 목록 -->
                    <c-table
                      title="LBL0001174"
                      :editable="editable&&!disabled&&selectScenario"
                      :columns="gridImpr.columns"
                      :data="scenario.imprs"
                      :gridHeight="gridImpr.height"
                      @linkClick="(row) => linkClick({ sopImprovementId: row.sopImprovementId }, row)"
                    >
                      <template slot="table-button">
                        <q-btn-group outline>
                          <!-- 개선요청 -->
                          <c-btn 
                            v-if="editable&&!disabled&&selectScenario" 
                            label="LBLIMPRREQUEST" 
                            icon="add" 
                            @btnClicked="openImprRequest" />
                        </q-btn-group>
                      </template>
                    </c-table>
                  </div>
                  <div class="col-4">
                    <!-- 사진 -->
                    <c-upload-picture
                      isMultiTask
                      :attachInfo="attachAfterInfo"
                      :editable="editable&&!disabled&&selectScenario&&afterRiskEditable">
                    </c-upload-picture>
                  </div>
                  <div class="col-5">
                    <!-- 추가 리스크관리 계획 -->
                    <c-textarea
                      :disabled="disabled||!selectScenario||!afterRiskEditable"
                      :editable="editable"
                      :rows="3"
                      label="LBL0001122"
                      name="improvementMeasures"
                      v-model="scenario.improvementMeasures">
                    </c-textarea>
                  </div>
                  <div class="col-3">
                    <!-- 개선 후 위험도 -->
                    <q-list dense bordered separator>
                      <q-item>
                        <div class="card-risk-info detail-risk">
                          <q-item-section>
                            <!-- 빈도 -->
                            <q-item-label>{{setLabel('LBL0001009')}}</q-item-label>
                          </q-item-section>

                          <q-item-section side>
                            <q-item-label>{{scenario.afterFrequency}}</q-item-label>
                          </q-item-section>
                        </div>
                        <q-separator spaced vertical />
                        <div class="card-risk-info detail-risk">
                          <q-item-section>
                            <!-- 강도 -->
                            <q-item-label>{{setLabel('LBL0001010')}}</q-item-label>
                          </q-item-section>

                          <q-item-section side>
                            <q-item-label>{{scenario.afterStrength}}</q-item-label>
                          </q-item-section>
                        </div>
                      </q-item>
                      <!-- <q-separator spaced inset /> -->
                      <q-item class="detail-risk">
                        <q-item-section>
                          <!-- 위험도 -->
                          <q-item-label>{{setLabel('LBL0001011')}}</q-item-label>
                        </q-item-section>

                        <q-item-section side class="detail-risk-num">
                          <q-item-label>{{scenario.afterRisk}}</q-item-label>
                        </q-item-section>
                        <q-item-section v-if="!disabled&&selectScenario&&afterRiskEditable" side class="detail-risk-num">
                          <q-btn 
                            unelevated round dense
                            size="10px"
                            color="primary" 
                            icon="create">
                            <q-popup-proxy ref="afterRiskProxy">
                              <component
                                :is="riskPopComponent"
                                width="800px"
                                :props="{
                                  row: {
                                    ramMatrixId: scenario.ramMatrixId
                                  }
                                }"
                                :editable="editable&&!disabled&&selectScenario"
                                @callback="(data, color) => callbackCardProxy(data, color, 'afterRiskProxy')"
                              />
                            </q-popup-proxy>
                          </q-btn>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </div>
                </template>
              </c-card>
            </div>
          </div>
        </q-form>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <c-win-pop 
      ref="winPopup"  
      @onClose="val=>evtCloseWinPopup(val)"  
      @onRecvEvtFromWinPop="val=>onRecvWinPop(val)" />
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import mixinConvert from '@/js/mixin-template-convert'
export default {
  name: 'jsa-scenario',
  mixins: [mixinConvert],
  props: {
    sop: {
      type: Object,
      default: () => ({
        ramJsaAssessJobId: '',  // 안전작업 일련번호
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        mdmSopId: '',  // 안전작업 표준 일련번호_sop 작업이 아닌경우는 "tmp_001"과 같이 생성
        sopFlag: '',  // sop 여부_sop에서 작업을 불러오는 경우
        jobName: '',  // 안전작업 명
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        scenarios: [],
        ramMatrixId: '',
      }),
    },
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        ramTechniqueCd: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        assessUserName: '',  // 평가 담당자
        draws: [],
        reCheckedCnt:0,
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        title: 'LBL0001164', // 평가
        flag: false,
        research: '',
      }),
    },
    planUpdateBtnData: {
      title: 'LBLPLAN', // 계획
      flag: false,
      research: '',
      planResearch: '',
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'jobStepName' },
          { index: 1, colName: 'riskHazardNameClass' },
          { index: 2, colName: 'riskHazardNameClass' },
        ],
        data: [],
      },
      gridImpr: {
        columns: [
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            style: 'width:85px',
            sortable: true
          },
          {
            name: 'improveRequestDeptName',
            field: 'improveRequestDeptName',
            // 요청부서
            label: 'LBL0000927',
            align: 'center',
            style: 'width:85px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: 'LBL0001018',
            align: 'center',
            style: 'width:85px',
            sortable: true,
          },
        ],
        height: '350px'
      },
      scenario: {
        ramJsaAssessScenarioId: '',  // 시나리오 일련번호_시나리오의 한 라인(원일별)
        ramJsaAssessJobId: '',  // 안전작업 일련번호
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정
        mdmSopId: '',  // 안전작업 표준 일련번호_sop 작업이 아닌경우는 "tmp_001"과 같이 생성
        sopFlag: '',  // sop 여부_sop에서 작업을 불러오는 경우
        jobName: '',  // 안전작업 명
        jobStepId: '',  // 작업단계일련번호
        jobStepNo: '',  // 작업단계 번호
        jobStepName: '',  // 작업단계 명
        jobStepDesc: '',  // 작업단계 설명
        ramRiskHazardId: '',  // 유해위험요인 코드
        riskHazardName: '',  // 유해위험요인 명
        riskOccurrenceCause: '',  // 위험발생 원인
        riskOccurrenceResult: '',  // 위험발생 결과
        relationLaw: '',  // 관련법규
        improveFlag: 'X',  // 개선여부
        currentSafetyMeasures: '',  // 현재 안전조치
        beforeRamMatrixRiskId: '',  // 전 위험도 no
        afterRamMatrixRiskId: '',  // 후 위험도 no
        improvementMeasures: '',  // 개선대책
        assessDate: '',  // 평가일
        assessUserId: '',  // 평가자 ID
        compareId: '',  // 비교 대상 일련번호(시나리오 일련번호)
        recheckFlag: '',  // 재검토 여부
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        imprs: [], // 개선목록
      },
      riskPopComponent: () => import(`${'@/pages/ram/base/matrixPop.vue'}`),
      improveFlagItems: [
        { code: 'X', codeName: '상관없음', },
        { code: 'Y', codeName: '개선진행', },
        { code: 'N', codeName: '유지', },
      ],
      attachHazardInfo: {
        isSubmit: '',
        taskClassCd: 'RISK_HAZARD',
        taskKey: '',
      },
      attachBeforeInfo: {
        isSubmit: '',
        taskClassCd: 'JSA_SCENARIO_BEFORE',
        taskKey: '',
      },
      attachAfterInfo: {
        isSubmit: '',
        task: []
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      activeWinProps: {
        processCd: '',
        ramJsaAssessScenarioId: '',
      },
      scenarioViewGroup: 'L',
      rowIndex: 0,
      editable: true,
      isSave: false,
      isSaveUnit: false,
      listUrl: '',
      detailUrl: '',
      listImprUrl: '',
      saveUrl: '',
      saveUnitUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    isCompared() {
      return this.process.reCheckedCnt > 0;
    },
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 150) + 'px' : '500px'
    },
    requestContentsCols() {
      return ['jobStepName', 'riskHazardNameClass', 'riskOccurrenceCause'];
    },
    gridColumns() {
      let cols = [];
      if (this.scenarioViewGroup === 'L') {
        cols = [
          {
            fix: true,
            name: 'jobStepName',
            field: 'jobStepName',
            description: 'jobStepDesc',
            // 작업단계
            label: 'LBL0001103',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskHazardPicture',
            field: 'riskHazardPicture',
            // 사진
            label: 'LBL0001175',
            align: 'center',
            style: 'width:40px',
            sortable: false,
            type: 'custom',
          },
          {
            fix: true,
            name: 'riskHazardNameClass',
            field: 'riskHazardNameClass',
            // 분류-유해위험요인
            label: 'LBL0001020',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            innerBtn: true,
            btns: [
              { label: '', icon: 'add', color: 'light-blue', value: '1', 
                disableTarget: 'jobStepId', disableCon: null,
                tooltip: '가능한 원인 추가 <font color="#F0F4C3"><b>(※ 관리되는 항목)</b></font>' },
              { label: '', icon: 'add', color: 'orange', value: '2', 
                tooltip: '원인 추가' },
            ],
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: 'LBL0001021',
            align: 'left',
            type: 'text',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: 'LBL0001006',
            align: 'left',
            style: 'width:170px',
            type: 'text',
            sortable: false,
            required: true,
          },
          {
            name: 'riskOccurrenceResult',
            field: 'riskOccurrenceResult',
            // 결과
            label: 'LBL0001007',
            align: 'left',
            style: 'width:170px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            type: 'text',
            style: 'width:150px',
            sortable: false,
            required: true,
          },
          {
            name: 'before',
            field: 'before',
            // 개선전
            label: 'LBL0001008',
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:70px',
                sortable: false,
                type: 'proxy',
                component: () => import(`${'@/pages/ram/base/matrixPop.vue'}`)
              },
            ]
          },
          {
            name: 'picture',
            field: 'picture',
            // 개선<br/>전/후<br/>사진
            label: 'LBL0001176',
            align: 'center',
            type: 'custom',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 개선대책
            label: 'LBL0001408',
            align: 'left',
            style: 'width:200px',
            type: 'text',
            sortable: false
          },
          {
            name: 'improveFlag',
            field: 'improveFlag',
            // 개선<br/>진행<br/>여부
            label: 'LBL0001177',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'select',
            comboItems: this.improveFlagItems
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL0001178',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:150px',
                type: 'custom',
                sortable: false
              },
            ]
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: 'LBL0001012',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:70px',
                sortable: false,
                required: true,
                type: 'proxy',
                component: () => import(`${'@/pages/ram/base/matrixPop.vue'}`)
              },
            ]
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: 'LBL0001013',
            align: 'center',
            type: 'date',
            style: 'width:130px',
            sortable: false,
            required: true,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            // 평가자
            label: 'LBL0001014',
            align: 'center',
            type: 'user',
            userId: 'assessUserId',
            style: 'width:80px',
            sortable: false,
            required: true,
          },
          {
            name: 'riskbookFlag',
            field: 'riskbookFlag',
            // 위험등록부
            label: 'LBL0001171',
            align: 'center',
            sortable: true,
            type: 'check',
            'true': 'Y',
            'false': 'N',
            disableTarget: 'riskRegisterFlag',
            disableCon: false
          },
        ]
      } else {
        cols = [
          {
            name: 'item',
            field: 'item',
            // 평가내용
            label: 'LBL0001179',
            align: 'left',
            sortable: false,
            type: 'custom'
          },
        ]
      }
      return cols;
    },
    selectScenario() {
      return Boolean(this.scenario.ramJsaAssessScenarioId)
    },
    scenarioTarget() {
      return this.selectScenario ? `${this.scenario.jobName} - ${this.scenario.jobStepName} - ${this.scenario.riskHazardNameClass}` : '';
    },
    afterRiskEditable() {
      return this.scenario.improveFlag !== 'N'
    }
  },
  watch: {
    'updateBtnData.research'() {
      this.getList();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('beforeunload', () => {
      this.$refs.winPopup.closeWinPop()
    });
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.$refs.winPopup.closeWinPop()
    window.removeEventListener('beforeunload', () => {
      this.$refs.winPopup.closeWinPop()
    });
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.jsa.scenario.list.url
      this.detailUrl = selectConfig.ram.jsa.scenario.get.url
      this.listImprUrl = selectConfig.ram.jsa.riskReduce.impr.url
      this.saveUrl = transactionConfig.ram.jsa.scenario.save.url
      this.saveUnitUrl = transactionConfig.ram.jsa.scenario.saveUnit.url
      this.deleteUrl = transactionConfig.ram.jsa.scenario.delete.url
      // code setting
      // list setting
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramJsaAssessJobId: this.sop.ramJsaAssessJobId,
      }
      this.$http.request((_result) => {
        this.sop.scenarios = _result.data;
      },);
    },
    getDetail(ramJsaAssessScenarioId) {
      this.$http.url = this.$format(this.detailUrl, ramJsaAssessScenarioId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.scenario, _result.data);

        this.$set(this.attachHazardInfo, 'taskKey', this.scenario.ramRiskHazardId)
        this.$set(this.attachBeforeInfo, 'taskKey', this.scenario.ramJsaAssessScenarioId)
        // this.$set(this.attachAfterInfo, 'taskKey', this.scenario.ramJsaAssessScenarioId)
        this.$set(this.attachAfterInfo, 'task', this.$_.map(this.scenario.imprs, impr => {
          return {
            taskClassCd: 'IBM_AFTER',
            taskKey: impr.sopImprovementId,
          }
        }))
      },);
    },
    getImprs() {
      this.$http.url = this.listImprUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramJsaAssessScenarioId: this.scenario.ramJsaAssessScenarioId,
      }
      this.$http.request((_result) => {
        this.$set(this.scenario, 'imprs', _result.data)
        this.getList(); // 시나리오 목록에도 개선이 업데이트 될 수 있도록 처리
        // let scenario = this.$_.find(this.sop.scenarios, { ramJsaAssessScenarioId: this.scenario.ramJsaAssessScenarioId })
        // if (scenario) {
        //   scenario.sopImprovementIds = this.$_.map(this.scenario.imprs, 'sopImprovementId')
        //   scenario.ibmStepNames = this.$_.map(this.scenario.imprs, 'ibmStepName')
        //   scenario.ibmClassCds = this.$_.map(this.scenario.imprs, 'ibmClassCd')
        //   scenario.ibmTooltip = this.scenario.imprs[0].ibmTooltip
        // }
      },);
    },
    research() {
      this.$emit('research');
    },
    /* eslint-disable no-unused-vars */
    imprChange(data) {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      this.research();
    },
    innerBtnClicked(col, props, btn) {
      if (col.name === 'riskHazardNameClass') {
        if (btn.value === '1') {
          this.rowIndex = props.rowIndex;
          this.popupOptions.title = 'LBL0001201'; // 유해위험요인별 원인/결과
          this.popupOptions.param = {
            type: 'multiple',
            searchType: '4', //1: 작업조회, 2:작업단계, 3:작업단계별 유해위험요인, 4:작업단계별 유해위험요인별 원인/결과
            subProcessCd: this.sop.processCd,
            jobStepId: props.row.jobStepId,
            mdmHazardFactorsId: props.row.ramRiskHazardId,
          };
          this.popupOptions.target = () => import(`${'@/pages/common/process/sopPop.vue'}`);
          this.popupOptions.width = '70%';
          this.popupOptions.visible = true;
          this.popupOptions.closeCallback = this.closeCausePopup;
        } else {
          this.sop.scenarios.splice(props.rowIndex, 0, {
            ramJsaAssessScenarioId: uid(), // 시나리오 일련번호_시나리오의 한 라인(원일별)
            ramJsaAssessJobId: this.sop.ramJsaAssessJobId,  // 안전작업 일련번호
            ramRiskAssessmentPlanId: this.sop.ramRiskAssessmentPlanId,  // 평가 일련번호
            processCd: this.sop.processCd,  // 단위공정 코드
            processName: this.sop.processName,  // 단위공정 코드
            jobStepId: props.row.jobStepId,  // 작업단계일련번호
            jobStepNo: props.row.jobStepNo,  // 작업단계 번호
            jobStepName: props.row.jobStepName,  // 작업단계 명
            jobStepDesc: props.row.jobStepDesc,  // 작업단계 설명
            ramRiskHazardId: props.row.ramRiskHazardId,  // 유해위험요인 코드
            riskHazardName: props.row.riskHazardName,  // 유해위험요인 명
            riskHazardNameClass: props.row.riskHazardNameClass,  // 유해위험요인 명
            relationLaw: props.row.relationLaw,  // 관련법규_유해위험요인 마스터에스 불러와서 편집후 저장
            riskOccurrenceCause: '',  // 위험발생 원인
            riskOccurrenceResult: '',  // 위험발생 결과
            currentSafetyMeasures: '',  // 현재 안전조치
            beforeRamMatrixRiskId: '',  // 전 위험도 no
            afterRamMatrixRiskId: '',  // 후 위험도 no
            improvementMeasures: '',  // 개선대책
            assessDate: this.$comm.getToday(),  // 평가일
            assessUserName: this.$_.clone(this.process.assessUserName),
            assessUserId: this.$_.clone(this.process.assessUserId),
            sopImprovementIds: '', 
            ibmStepNames: '', 
            riskbookFlag: 'N', 
            editFlag: 'C',
            ramMatrixId: this.sop.ramMatrixId,
            // 위험등록부 등재를 위한 prop
            riskRegisterFlag: false,
          });
        }
      }
    },
    closeCausePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          this.sop.scenarios.splice(this.rowIndex, 0, {
            ramJsaAssessScenarioId: uid(), // 시나리오 일련번호_시나리오의 한 라인(원일별)
            ramJsaAssessJobId: this.sop.ramJsaAssessJobId,  // 안전작업 일련번호
            ramRiskAssessmentPlanId: this.sop.ramRiskAssessmentPlanId,  // 평가 일련번호
            processCd: this.sop.processCd,  // 단위공정 코드
            processName: this.sop.processName,  // 단위공정 코드
            jobStepId: item.jobStepId,  // 작업단계일련번호
            jobStepNo: item.jobStepNo,  // 작업단계 번호
            jobStepName: item.jobStepName,  // 작업단계 명
            jobStepDesc: item.jobStepDesc,  // 작업단계 설명
            ramRiskHazardId: item.mdmHazardFactorsId,  // 유해위험요인 코드
            riskHazardName: item.riskHazardName,  // 유해위험요인 명
            riskHazardNameClass: item.ramRiskHazardClassName ? 
              item.ramRiskHazardClassName + ' - ' + item.riskHazardName : item.riskHazardName,  // 유해위험요인 명
            riskOccurrenceCause: item.hazardOccurReason,  // 위험발생 원인
            riskOccurrenceResult: item.hazardOccurResult,  // 위험발생 결과
            relationLaw: '',  // 관련법규_유해위험요인 마스터에스 불러와서 편집후 저장
            currentSafetyMeasures: '',  // 현재 안전조치
            beforeRamMatrixRiskId: '',  // 전 위험도 no
            afterRamMatrixRiskId: '',  // 후 위험도 no
            improvementMeasures: '',  // 개선대책
            assessDate: this.$comm.getToday(),  // 평가일
            assessUserName: this.$_.clone(this.process.assessUserName),
            assessUserId: this.$_.clone(this.process.assessUserId),
            sopImprovementIds: '', 
            ibmStepNames: '', 
            riskbookFlag: 'N', 
            editFlag: 'C',
            ramMatrixId: this.sop.ramMatrixId,
            // 위험등록부 등재를 위한 prop
            riskRegisterFlag: false,
          });
        });
      }
    },
    openImprRequest() {
      let requestContents = this.scenario.jobStepName
      if (this.scenario.riskHazardNameClass) {
        requestContents += ' / ' + this.scenario.riskHazardNameClass
      }
      if (this.scenario.riskOccurrenceCause) {
        requestContents += ' / ' + this.scenario.riskOccurrenceCause
      }
      
      this.popupOptions.title = 'LBLIMPRREQUEST';
      this.popupOptions.param = {
        requestContents: requestContents,
        relationTableKey: this.scenario.ramJsaAssessScenarioId,
        ibmTaskTypeCd: 'ITT0000015',
        ibmTaskUnderTypeCd: 'ITTU000020',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCardImprPopup;
    },
    closeCardImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.getImprs();
    },
    linkClick(data, row) {
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        sopImprovementId: data.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCardImprPopup;
    },
    /* eslint-disable no-unused-vars */
    callbackProxy(data, props, col) {
      if (col.name === 'beforeRisk') {
        this.$set(this.sop.scenarios[props.rowIndex], 'beforeFrequency', data.f)
        this.$set(this.sop.scenarios[props.rowIndex], 'beforeStrength', data.s)
        this.$set(this.sop.scenarios[props.rowIndex], 'beforeRisk', data.r)
        this.$set(this.sop.scenarios[props.rowIndex], 'beforeRamMatrixRiskId', data.ramMatrixRiskId)

        if (this.process.scenarios[props.rowIndex].improveFlag === 'N') {
          this.$set(this.process.scenarios[props.rowIndex], 'afterFrequency', data.f)
          this.$set(this.process.scenarios[props.rowIndex], 'afterStrength', data.s)
          this.$set(this.process.scenarios[props.rowIndex], 'afterRisk', data.r)
          this.$set(this.process.scenarios[props.rowIndex], 'afterRamMatrixRiskId', data.ramMatrixRiskId)
        }
      } else {
        this.$set(this.sop.scenarios[props.rowIndex], 'afterFrequency', data.f)
        this.$set(this.sop.scenarios[props.rowIndex], 'afterStrength', data.s)
        this.$set(this.sop.scenarios[props.rowIndex], 'afterRisk', data.r)
        this.$set(this.sop.scenarios[props.rowIndex], 'afterRamMatrixRiskId', data.ramMatrixRiskId)
      }
      if (this.sop.scenarios[props.rowIndex].editFlag !== 'C') {
        this.$set(this.sop.scenarios[props.rowIndex], 'editFlag', 'U')
      }
    },
    callbackCardProxy(data, color, refName) {
      if (refName === 'beforeRiskProxy') {
        // 개선 전
        this.$set(this.scenario, 'beforeFrequency', data.f)
        this.$set(this.scenario, 'beforeStrength', data.s)
        this.$set(this.scenario, 'beforeRisk', data.r)
        this.$set(this.scenario, 'beforeRamMatrixRiskId', data.ramMatrixRiskId)

        if (this.scenario.improveFlag === 'N') {
          this.$set(this.scenario, 'afterFrequency', data.f)
          this.$set(this.scenario, 'afterStrength', data.s)
          this.$set(this.scenario, 'afterRisk', data.r)
          this.$set(this.scenario, 'afterRamMatrixRiskId', data.ramMatrixRiskId)
        }
      } else {
        // 개선 후
        this.$set(this.scenario, 'afterFrequency', data.f)
        this.$set(this.scenario, 'afterStrength', data.s)
        this.$set(this.scenario, 'afterRisk', data.r)
        this.$set(this.scenario, 'afterRamMatrixRiskId', data.ramMatrixRiskId)
      }
      this.$refs[refName].hide();
      this.$forceUpdate();
    },
    tableDataChange(props, col) {
      if (col.name === 'improveFlag') {
        // 개선을 진행 할 건지 여부 체크
        if (props.row[col.name] === 'N') {
          this.$set(props.row, 'improvementMeasures', '현상 유지')
          this.$set(props.row, 'afterFrequency', props.row.beforeFrequency)
          this.$set(props.row, 'afterStrength', props.row.beforeStrength)
          this.$set(props.row, 'afterRisk', props.row.beforeRisk)
          this.$set(props.row, 'afterRamMatrixRiskId', props.row.beforeRamMatrixRiskId)
        }
      }
    },
    listView() {
      this.scenarioViewGroup = 'L'
    },
    cardView() {
      this.scenarioViewGroup = 'C'
    },
    improveFlagChange() {
      if (this.scenario.improveFlag === 'N') {
        // 유지하는 경우 개선 전 위험도를 복사 및 계획에는 현상유지 setting
        this.$set(this.scenario, 'improvementMeasures', '현상 유지')
        this.$set(this.scenario, 'afterFrequency', this.scenario.beforeFrequency)
        this.$set(this.scenario, 'afterStrength', this.scenario.beforeStrength)
        this.$set(this.scenario, 'afterRisk', this.scenario.beforeRisk)
        this.$set(this.scenario, 'afterRamMatrixRiskId', this.scenario.beforeRamMatrixRiskId)
      }
    },
    saveScenario() {
      if (this.$comm.validTable(this.gridColumns, this.sop.scenarios)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.sop.scenarios, item => {
              item.regUserId = this.$store.getters.user.userId
              item.chgUserId = this.$store.getters.user.userId
            })
            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveScenarioCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.research();
      this.getList();
    },
    saveScenarioUnit() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.scenario.chgUserId = this.$store.getters.user.userId

              this.isSaveUnit = !this.isSaveUnit
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveScenarioUnitCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.research();
      this.getList();
    },
    removeScenario() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.sop.scenarios.splice(this.$_.findIndex(this.sop.scenarios, item), 1)
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();

              this.planUpdateBtnData.planResearch = uid();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    openHazardPicture(props) {
      this.popupOptions.title = 'LBL0001110'; // 유해위험요인 사진
      this.popupOptions.param = {
        ramRiskHazardId: props.row.ramRiskHazardId,
        disabled: this.disabled
      };
      this.popupOptions.target = () => import(`${'@/pages/common/hazard/riskHazardPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPicturePopup;
    },
    closeHazardPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openImprPicture(props) {
      this.popupOptions.title = 'LBL0001181'; // 개선 사진
      this.popupOptions.param = {
        ramJsaAssessScenarioId: props.row.ramJsaAssessScenarioId,
        sopImprovementIds: props.row.sopImprovementIds,
        disabled: this.disabled
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/jsa/action/jsaScenarioImprPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    compareScenario() {
      let uri = `/ram/compare/compareScenario?ramTechniqueCd=${this.param.ramTechniqueCd}&ramRiskAssessmentPlanId=${this.param.ramRiskAssessmentPlanId}&processCd=${this.process.processCd}&jobName=${this.sop.jobName}`;
      this.$refs.winPopup.openWinPop(uri, 1560, 700);
    },
    evtCloseWinPopup() {
      Object.assign(this.$data.activeWinProps, this.$options.data().activeWinProps);
    },
    onRecvWinPop(recvObj) {
      if(recvObj) {
        this.$set(this.activeWinProps, 'processCd', recvObj.processCd)
        this.$set(this.activeWinProps, 'ramJsaAssessScenarioId', recvObj.ramJsaAssessScenarioId)
      }
    },
    setTrClass(props) {
      // console.log(props.row.processCd, '  ', this.activeWinProps.processCd)
      // console.log(props.row.compareId, '  ', this.activeWinProps.ramJsaAssessScenarioId)
      return props.row.processCd === this.activeWinProps.processCd 
        && props.row.compareId === this.activeWinProps.ramJsaAssessScenarioId ? 'bg-purple-2' : '';
    },
  }
};
</script>
<style lang="sass">
.scenario-chip i 
  margin-right: -0.2em !important
</style>
<style lang="sass">
.scenario-chip i 
  margin-right: -0.2em !important

.scenario-view-group 
  button
    margin: 0 !important

.scenario-card-title
  margin-left: -5px
  font-size: 0.8rem

.scenario-card-side
  text-align: center
  align-self: center
  // flex-basis: 50%

.card-risk-item
  padding-top: 0px
  padding-bottom: 0px
  min-height: auto
.card-risk-info
  width: auto
  min-width: 0
  max-width: 100%
  flex: 10000 1 0%
  display: flex
  border-radius: 10px
  font-size: 1rem
  height: 30px
.before-risk
  background: #3A98B9
  margin-right: 5px
.after-risk
  background: #3A98B9
  margin-left: 5px
.risk-number
  font-size: 1.3rem

.detail-risk
  font-size: 0.9rem
  font-weight: 600
.detail-risk-num
  font-size: 1rem
  font-weight: 700
  color: #e63946

.card-scenario-list
  padding: 0px 16px !important
  min-height: 28px
</style>